import logo from "./logo.png";
import Gbutton from "./icons8-google.svg";
import "./App.css";
import React, { useEffect, useState } from "react";

import { gapi, loadAuth2 } from "gapi-script";
import { useMatch } from "react-router-dom";

import { Player } from "@lottiefiles/react-lottie-player";

import { UserCard } from "./UserCard";
import axios from "axios";

// const redirectURL = "exp://192.168.33.11:19000/--/info";

function App() {
  const match = useMatch("/:key");

  console.log("Parameter from URL:", match.params.key);

  // const clientId =
  //   process.env.REACT_APP_PERSONAL_CLIENT_ID

  const clientId = process.env.REACT_APP_OGWUGO_CLIENT_ID;

  //const redirectURL = "exp://192.168.19.201:8081/--/info";

  const redirectURL = "com.ogwugo.ogwugofood://info";

  const [user, setUser] = useState(null);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const setAuth2 = async () => {
      const auth2 = await loadAuth2(gapi, clientId, "");
      if (auth2.isSignedIn.get()) {
        window.location.replace(
          `${redirectURL}/token=${
            auth2.currentUser
              .get()
              .getAuthResponse({ includeAuthorizationData: true }).access_token
          }`
        );

        updateUser(auth2.currentUser.get());
      } else {
        attachSignin(document.getElementById("customBtn"), auth2);
      }
    };
    setAuth2();

    setTimeout(()=> {
      setShowButton(true)
      // const customBtn = document.getElementById("customBtn")

      // if(customBtn){
      //   customBtn.click()
      // }
    }, 4000)
  }, []);

  useEffect(() => {
    if (!user) {
      const setAuth2 = async () => {
        const auth2 = await loadAuth2(gapi, clientId, "");

        attachSignin(document.getElementById("customBtn"), auth2);
      };
      setAuth2();
      // navigate("/");
    }
  }, [user]);

  const updateUser = (currentUser) => {
    const name = currentUser.getBasicProfile().getName();
    const profileImg = currentUser.getBasicProfile().getImageUrl();
    setUser({
      name: name,
      profileImg: profileImg,
    });
  };

  const attachSignin = (element, auth2) => {
    auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        window.location.replace(
          `${redirectURL}/token=${
            googleUser.getAuthResponse({ includeAuthorizationData: true })
              .access_token
          }`
        );

        // ogwugoSignIn(googleUser.getAuthResponse({includeAuthorizationData:true}).access_token)
        updateUser(googleUser);
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  };

  const signOut = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      setUser(null);
      console.log("User signed out.");
    });
  };

  if (user) {
    return (
      <div className="container">
        <Player
          style={{ height: "30%", width: 300 }}
          src={
            "https://lottie.host/a8424a25-2eb4-48de-a61f-3ca2e25ac6d9/VYJ1XpIEbb.json"
          }
          loop
          autoplay
        />

<h4 style={{ textAlign: "center" }}>You will be redirected to the app</h4>
        {/* <UserCard user={user} />
        <div id="" className="btn logout" onClick={signOut}>
          Logout
        </div> */}
      </div>
    );
  }

  return (
    <div className="container">
      <div id="customBtn" className="btn-login">
        <img
          style={{ height: "50px", width: "30px", left: 0 }}
          src={Gbutton}
          alt="React Logo"
        />
        <div
          style={{
            alignSelf: "center",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p style={{ textAlign: "center", alignSelf: "center" }}>Continue</p>
        </div>
      </div>
      <img
        src={logo}
        style={{
          height: "30%",
          width: 200,
          alignSelf: "center",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />

      {showButton ? (
        <div id="customBtn" className="btn-login">
          <img
            style={{ height: "50px", width: "30px", left: 0 }}
            src={Gbutton}
            alt="React Logo"
          />
          <div
            style={{
              alignSelf: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <p style={{ textAlign: "center", alignSelf: "center" }}>Continue</p>
          </div>
        </div>
      ) : (
        <Player
          style={{ height: "30%", width: 300 }}
          src={
            "https://lottie.host/a8424a25-2eb4-48de-a61f-3ca2e25ac6d9/VYJ1XpIEbb.json"
          }
          loop
          autoplay
        />
      )}

      <h4 style={{ textAlign: "center" }}>
        We are signing you into google, please wait...
      </h4>
      <h4 style={{ textAlign: "center" }}>You will be redirected to the app</h4>
    </div>
  );
}

export default App;
